.slider_image {
    height: 100vh;
    width: 100vw;
    overflow: hidden; /* Ensures no overflow outside the container */
  }
  .qrcode-container {
    position: absolute;
    bottom: 60px;
    right: 10px;
    z-index: 10;
    background: white;
    border-radius: 8px;
  }