body, html {
    margin: 0;
    padding: 0;
    height: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}
.main_wrapper{
    height: 100%;
    width: 100%;
}
.location_text{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.topbar {
    width: 100%;
    height: 100px;
    background-color: #007bff; /* Blue color for topbar */
}

.mainbar {
    width: 50%;
    height: 600px;
    margin-top: -100px; /* Overlap topbar by adjusting margin */
    z-index: 1; /* Ensure mainbar appears above topbar */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
}
.trillboard_wrap {
    background: #3CC7E3;
    padding: 25px;
    border-radius: 10px;
    
}
.trillboard_image{
    text-align: center;
}
.trillboard_image img{
    width: 115px;
    height: 115px;
}
.trillboard_scanboard{
    background: white;
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
display: flex;
flex-direction: column;
height: 100%;
width: 60%;
justify-content: center;
margin: -23px auto 0px;
border-radius: 10px;
padding: 25px 25px 0px;
}
section.trillboard_scanboard.sucess_connect {
    align-items: center;
}
.connect_img{
    margin-top: 30px;
}
.scanorcode_wrap img{
    width: 300px;
}
.scanorcode_wrap h2{
    font-size: 30px;
font-family: sans-serif;
text-align: center;
margin: 0;
color: black;
}
.scanorcode_wrap{
    text-align: center;
    right: 0;
    bottom: 10px;
}
.trillboard_text h2{
    font-size: 24px;
    font-family: sans-serif;
    text-align: center;
    color: #484848;
}
.trillboard_gst h4{
    text-align: end;
    font-size: 18px;
    color: #000;
    font-family: sans-serif;
}
.trillboard_videeo_list {
width: 100%;
background: #EF9245;
display: flex;
justify-content: space-between;
padding: 25px;
margin-top: 26px;
}
.trillboard_list p{
font-size: 18px;
font-family: sans-serif;
font-weight: 500;
}

#loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:transparent; /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure it's above other content */
  }
  
  /* Loader Animation */
  .loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }




@media only screen and (max-width: 767px) {
.trillboard_scanboard {
background: white;
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
display: flex;
flex-direction: column;
height: 100%;
width: 100%;
justify-content: center;
align-items: center;
margin: -23px auto 0px;
border-radius: 10px;
padding: 25px 0px 0px;
}
.scanorcode_wrap img {
width: 100%;
}
.trillboard_videeo_list {
width: 100%;
background: #EF9245;
display: block;
justify-content: space-between;
padding: 16px 10px;
margin-top: 26px;
}
.scanorcode_wrap h2 {
font-size: 20px;
font-family: sans-serif;
text-align: center;
margin: 0;
color: black;
}
.trillboard_list p {
font-size: 15px;
font-family: sans-serif;
font-weight: 500;
}
.trillboard_video_image {
text-align: center;
}
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
.trillboard_scanboard {
background: white;
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
display: flex;
flex-direction: column;
height: 100%;
width: 85%;
justify-content: center;
align-items: center;
margin: -23px auto 0px;
border-radius: 10px;
padding: 25px 25px 0px;
}
}
